// src/plugins/custom-money-input.js
import CustomMoneyInput from '@/components/CustomMoneyInput.vue';

const CustomMoneyInputPlugin = {
  install(Vue, options = {}) {
    const defaultOptions = {
      decimal: '.',
      thousands: ',',
      prefix: '$ ',
      suffix: '',
      precision: 2,
      masked: false,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    // Registering CustomMoneyInput globally
    Vue.component('CustomMoneyInput', {
      extends: CustomMoneyInput,  // Extending the component
      props: {
        globalOptions: {
          type: Object,
          default: () => mergedOptions,  // Default value of globalOptions is mergedOptions
        },
      },
      // Ensure globalOptions are injected properly into your component if needed
    });
  },
};

export default CustomMoneyInputPlugin;
