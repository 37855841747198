<!-- filepath: /c:/git/SimpleEnvelope-fe/src/components/CustomMoneyInput.vue -->
<template>
    <input :class="inputClass" :id="id" :value="formattedValue" @input="handleEvent('input', $event)"
        @change="handleEvent('change', $event)" @keydown="handleKeydown" :size="size" type="text"
        :placeholder="placeholder" />
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        currency: {
            type: String,
            default: 'USD',
        },
        locale: {
            type: String,
            default: 'en-US',
        },
        decimals: {
            type: Number,
            default: 2,
        },
        prefix: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        inputClass: {
            type: [String, Array, Object],
            default: '',
        },
        globalOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            rawValue: this.value, // Store the raw numeric value
            debounceTimeout: null, // Store the debounce timeout
            removekeypressed: false,
        };
    },
    computed: {
        formattedValue() {
            return this.formatCurrency(this.rawValue);
        },
    },
    watch: {
        value(newValue) {
            // console.log('watch');
            // Sync rawValue when the prop changes externally
            this.handleEvent('input', { target: { value: newValue } });
        },
    },
    methods: {
        parseRawValue(value) {
            // console.log(`value: ${value}`); 
            //ensure value is a string
            value = value.toString();
            const isNegative = value.startsWith('-');
            value = value.replace(/[^\d.]/g, ''); // Remove all characters except digits and dots
            if (value.includes('.')) {
                //if value has more than one decimal point, remove all except the last decimal point
                const parts = value.split('.');
                value = parts.shift() + '.' + parts.join('');
                // Ensure that the decimal part has the correct number of decimals
                const [integerPart, decimalPart] = value.split('.');
                if (decimalPart.length != this.decimals) {
                    console.log(`this.removekeypressed`, this.removekeypressed);

                    if (decimalPart.length < this.decimals) {
                        console.log(`decimalPart.length < this.decimals`);
                        if (!this.removekeypressed) {
                            console.log('padding decimal part');
                            value = `${integerPart}.${decimalPart.padEnd(this.decimals, '0')}`;
                        }
                        console.log('reset removekeypressed');
                        this.removekeypressed = false;
                    }

                    value = value.replace('.', '');
                    const newIntegerPart = value.slice(0, value.length - this.decimals);
                    const newDecimalPart = value.slice(value.length - this.decimals);
                    value = `${newIntegerPart}.${newDecimalPart}`;

                }

            }
            return isNegative ? `-${value}` : value || 0;
        },
        handleEvent(type, event) {
            console.log('handleEvent');
            console.log(type);
            var newInputValue = event.target.value;
            newInputValue = this.parseRawValue(newInputValue);
            this.rawValue = newInputValue;
            this.$emit(type, Number(this.rawValue));
            event.target.value = this.formattedValue; // Update the input value to remove invalid characters
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.$emit(`debounced${type}`, Number(this.rawValue));
            }, 300); // Adjust the delay as needed
        },
        handleKeydown(event) {
            if (event.key === '-') {
                event.preventDefault();
                this.rawValue = -Math.abs(this.rawValue);
                this.$emit('input', this.rawValue);
                this.$emit('change', this.rawValue);
                event.target.value = this.formattedValue; // Update the input value to reflect the change
            } else if (event.key === '+') {
                event.preventDefault();
                this.rawValue = Math.abs(this.rawValue);
                this.$emit('input', this.rawValue);
                this.$emit('change', this.rawValue);
                event.target.value = this.formattedValue; // Update the input value to reflect the change
            } else if (event.key === 'Backspace' || event.key === 'Delete') {
                console.log('Backspace or Delete key pressed');
                this.removekeypressed = true;
                console.log(`this.removekeypressed`, this.removekeypressed);
            }
            this.$emit('keydown', event); // Emit the keydown event
        },
        formatCurrency(value) {
            const number = Number(value);
            return isNaN(number)
                ? ''
                : number.toLocaleString(this.locale, {
                    style: 'currency',
                    currency: this.currency,
                    minimumFractionDigits: this.decimals,
                    maximumFractionDigits: this.decimals,
                });
        },
    },
};
</script>

<style scoped>
.error {
    color: red;
}

.success {
    color: green;
}

input {
    color: black;
    caret-color: black;
}
</style>