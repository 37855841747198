<template>
  <div class="item my-5">
    <b-row class="mb-4">
      <b-col cols="12" md="6">
        <div v-if="!EnvelopeTotalAccurate">
          <h2 id="EnvelopeTotal">Envelopes Total: {{ formatcurency(EnvelopeTotal) }} ***</h2>
          <b-tooltip target="EnvelopeTotal" triggers="hover">
            Unassigned Transactions({{ noenvelope.length }}): {{ formatcurency(NoEnvelopeTransactionsAmountTotal) }}
            <br />
            Transactions without Account({{ noaccount.length }}): {{ formatcurency(NoAccountTransactionsAmountTotal) }}
            <br />
            Actual Envelope Total: {{ formatcurency(EnvelopeTotal + TotalOffset) || formatcurency(EnvelopeTotal) }}
          </b-tooltip>
        </div>

        <div v-else>
          <h2 id="EnvelopeTotal">Envelopes Total: {{ formatcurency(EnvelopeTotal) }}</h2>
          <b-tooltip target="EnvelopeTotal" triggers="hover">
            This amount should match the total of your bank account balances.
          </b-tooltip>
        </div>

      </b-col>
      <b-col cols="12" md="6" class="d-flex">
        <b-form-input class="p-4" v-model="filter" placeholder="Search..."></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          <b-button class="ml-2" v-b-modal.NewEnvelope>+</b-button>
        </b-input-group-append>
        <b-button class="ml-2" variant="success" v-b-modal.fundenvelopes>Fund</b-button>
      </b-col>
    </b-row>
    <b-table class="classtable" striped hover outlined bordered responsive show-empty :busy="EnvelopeTableisBusy" :items="EnvelopeBalances"
      :fields="EnvelopesfieldsCalc" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter">

      <template #empty="scope">
        <h4>{{ "No Envelopes where loaded..." }}</h4>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Data is loading...</strong>
        </div>
      </template>
      <template #emptyfiltered="scope">
        <h4>{{ "No Envelopes fit your search, please try a different ones." }}</h4>
      </template>
      <template v-slot:cell(dateformated)="data">{{
        new Date(data.item.date).format("YYYY-MM-DD hh:mm A")
      }}</template>
      <template v-slot:cell(balanceFormated)="data">
        <div v-if="data.item.balance >= 0" class="success">
          {{ formatcurency(data.item.balance) }}
        </div>
        <div v-if="data.item.balance < 0" class="error">
          {{ formatcurency(data.item.balance) }}
        </div>
      </template>
      <template v-slot:cell(BudgetAmount)="data">
        <div v-if="data.item.budget?.amount">
          {{ formatcurency(data.item.budget?.amount) || "" }}
        </div>
      </template>
      <template v-slot:cell(NameEdit)="data">
        <div v-if="data.item.edit == true">
          <b-form-input v-model="data.item.name" />
        </div>
        <div v-else>
          <a :href="`/Envelopes/${data.item.id}`">{{ data.item.name }}</a>
        </div>
      </template>
      <template v-slot:cell(CategoriesEdit)="data">
        <div v-if="data.item.edit == true">
          <model-select id="input-envelope" :options="CategoriesOptions" v-model="data.item.categoryId">
          </model-select>
        </div>
        <div v-else>
          {{ data.item.category.name }}
        </div>
      </template>
      <template v-slot:cell(Actions)="data">
        <b-button-group>
          <b-button size="sm" @click="
            saveEnvelope(data.item);
          data.item.edit = !data.item.edit;
          " class="mr-1">
            <b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button>
          <b-button v-if="data.item.edit" size="sm" v-b-modal.toenvelopes @click="Delete.fromenvelopeId = data.item.id"
            class="mr-1" variant="danger">
            <b-icon-trash />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <NewItem newtype="Envelope" />
    <b-modal title="Fund Envelopes" @show="ShowFundEnvelopes" @ok="FundEnvelopes" :ok-disabled="!FundEnvelopesData.profile || !FundEnvelopesData.FromEnvelope
      " id="fundenvelopes">
      <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Income profile"
        label-for="input-envelope">
        <b-select @change="GetProfileDetails()" id="input-profile" v-model="FundEnvelopesData.profile">
          <b-select-option v-for="n in Allsettings.profileCount" :key="n" :text="`Profile ${n}`" :value="n">
            Profile #{{ n }}
          </b-select-option>

          <!-- <b-select-option
            :value="1">Profile #1</b-select-option>
          <b-select-option :value="2">Profile #2</b-select-option>
          <b-select-option :value="3">Profile #3</b-select-option> -->
        </b-select>
      </b-form-group>
      <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Fund from Envelope"
        label-for="input-envelope">
        <b-input-group>
          <model-select id="input-envelope" :options="EnvelopeOptions" v-model="FundEnvelopesData.FromEnvelope">
          </model-select>
          <!-- <div v-bind:class="FundEnvelopesBalance < 0 ? 'error' : 'success'">
            {{ formatcurency(FundEnvelopesBalance) }}
          </div> -->


          <div v-if="FundEnvelopesData.FromEnvelope && FundingTotal != 0" style="display: flex">
            <div v-bind:class="FundEnvelopesBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(FundEnvelopesBalance) }}
            </div>
            <div>==></div>
            <div v-bind:class="Number(FundEnvelopesBalance) - Number(FundingTotal) < 0 ? 'error' : 'success'">
              {{ formatcurency(Number(FundEnvelopesBalance) - Number(FundingTotal)) }}
            </div>
          </div>
          <div v-bind:class="FundEnvelopesBalance < 0 ? 'error' : 'success'"
            v-if="FundEnvelopesData.FromEnvelope && FundingTotal == 0">
            {{ formatcurency(FundEnvelopesBalance) }}
          </div>
          <div v-bind:class="FundEnvelopesBalance < 0 ? 'error' : 'success'" v-else>

          </div>

        </b-input-group>
      </b-form-group>
      <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Funding Total:"
        label-for="input-funding">
        <b-input-group>
          <div v-bind:class="FundingTotal < 0 ? 'error' : 'success'">
           {{ formatcurency(FundingTotal) }}
          </div>
        </b-input-group>
      </b-form-group>
      <b-table class="classtable" small striped hover outlined bordered responsive show-empty :items="ProfileDetails"
        :fields="Profilefields">
        <template v-slot:cell(amountdol)="data">
          <CustomMoneyInput v-model="data.item.amount" class="form-control"></CustomMoneyInput>
        </template>
        <template v-slot:cell(futureamount)="data">
          {{ formatcurency(Number(envelopeBalance(data.item.envelopeId)) + Number(data.item.amount)) }}
        </template>
        <template v-slot:cell(EnvelopeEdit)="data">
          <div >
            <model-select @input="UpdateFundItemBalance(data.index, data.item)" id="input-envelope" :options="EnvelopeOptions" v-model="data.item.envelopeId" />
          </div>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button @click="ProfileDetails.splice(data.index, 1)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
      <b-button size="sm" @click="ProfileDetailsAddRow()"> + </b-button>
    </b-modal>
    <b-modal title="Delete Envelope:" @ok="DeleteAction" id="toenvelopes">
      <!-- <p>
        In order to delete this envelope, all transactions will need their envelope unassigned or transfered to another
        envelope.
      </p> -->
      <p>
        In order to delete this envelope, all transactions will need to be transfered to another envelope.
      </p>
      <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="7" label="Target Envelope:"
        label-for="input-envelope">
        <model-select id="input-envelope" :options="DeleteEnvelopeOptions" v-model="Delete.toenvelopeId">
        </model-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import NewItem from "./NewItem.vue";
import { ModelSelect } from "vue-search-select";

export default {
  name: "Envelopes",
  components: { NewItem, ModelSelect },
  // watch: {
  //   ProfileDetails: {
  //     handler(newVal, oldVal) {
  //       console.log(newVal);
  //       // This function will be called when ProfileDetails changes
  //       // newVal is the new value, oldVal is the old value
  //       // You can perform your desired operations here
  //       if (this.ProfileDetails && newVal) {
  //         this.ProfileDetails = this.ProfileDetails.filter((i) => {
  //             // console.log(i.envelopeId)
  //             // console.log(this.EnvelopeBalances)
  //             // console.log(this.EnvelopeBalances.find(envelope => envelope.id === i.envelopeId))
  //             i.balance = Number(this.EnvelopeBalances.find(envelope => envelope.id === i.envelopeId)?.balance || 0);
  //             i.amount = Number(i.amount);
  //             return i.amount > 0;
  //           });
  //       }

  //     },
  //     deep: true // This is needed to watch changes in the properties of ProfileDetails
  //   }
  // },
  methods: {
    //find the balance of the envelope
    envelopeBalance(envelopeId) {
      if (this.EnvelopeBalances) {
        var balance = this.EnvelopeBalances.filter(
          (i) => i.id == envelopeId
        )
        if (balance.length > 0) {
          return balance[0].balance;
        } else {
          return 0;
        }
      }
      return 0;
    },
    UpdateFundItemBalance(index, item) {
      console.log(item.envelopeId);
      console.log(index)
      if (index && item.envelopeId) {
        this.ProfileDetails[index].balance = this.EnvelopeBalances.find(
          (i) => i.id === item.envelopeId
        ).balance || 0;
      }
    },
    ShowFundEnvelopes() {
      this.FundEnvelopesData.FromEnvelope = this.Allsettings.defaultenvelope;
      this.GetProfileDetails();
    },
    ProfileDetailsAddRow() {
      const newrow = { id: null, envelopeId: null, amount: 0 };
      this.ProfileDetails.push(newrow);
    },
    ProfileDetailsDeleteRow(index) {
      // console.log(index);
      this.ProfileDetails.slice(index);
    },
    async saveEnvelope(i) {
      if (i.edit) {
        //console.log(i);
        // const token = await this.$auth.getTokenSilently();

        await axios.post("/api/envelopes", i).then((p) => {
          var category = this.categories.filter((t) => {
            return t.id == i.categoryId;
          });
          var envelope = this.EnvelopeBalances.findIndex((t) => {
            return t.id == i.id;
          });
          //console.log(envelope[0].name);
          this.EnvelopeBalances[envelope].category = category[0];
        });
      }
    },
    async GetProfileDetails() {
      if (this.FundEnvelopesData.profile) {
        var results = [];
        await axios
          .get(`/api/IncomeProfiles/${this.FundEnvelopesData.profile}`)
          .then((res) => {

            this.ProfileDetails = res.data.filter((i) => {
              // console.log(i.envelopeId)
              // console.log(this.EnvelopeBalances)
              // console.log(this.EnvelopeBalances.find(envelope => envelope.id === i.envelopeId))
              i.balance = Number(this.EnvelopeBalances.find(envelope => envelope.id === i.envelopeId)?.balance || 0);
              i.amount = Number(i.amount);
              return i.amount > 0;
            });
            //console.log(res.data);
            return results;
          });
      }
    },
    async FundEnvelopes() {
      //console.log(this.FundEnvelopesData);
      var data = this.ProfileDetails.filter((i) => i.envelope !== null);
      //console.log(data);
      data = data.map((i) => {
        return {
          amount: i.amount,
          fromenvelopeId: this.FundEnvelopesData.FromEnvelope,
          toenvelopeId: i.envelopeId,
        };
      });
      //console.log(data);
      await axios.post("/api/transfer/bulk", data).then((e) => {
        setTimeout(
          function () {
            console.log("test");
            this.refresh();
          }.bind(this),
          1000
        );
      });
    },
    async DeleteAction() {
      //console.log("test");
      var index = this.EnvelopeBalances.findIndex((t) => {
        return t.id == this.Delete.fromenvelopeId;
      });
      var item = this.EnvelopeBalances[index];
      if (confirm(`Delete Envelope? ${item.name}`)) {
        await axios
          .delete("/api/envelopes", { data: this.Delete })
          .then(this.EnvelopeBalances.splice(index, 1));
      }
    },
    async refresh() {
      this.EnvelopeTableisBusy = true;
      axios.get("/api/Transactions/noenvelope").then((res) => (this.noenvelope = res.data));
      axios.get("/api/Transactions/noaccount").then((res) => (this.noaccount = res.data));
      // this.token = await this.$auth.getTokenSilently();
      // console.log( await this.AuthorizationHeader);
      axios.get("/api/Envelopes/balances").then((res) => {

        this.EnvelopeBalances = res.data.map((i) => {
          i.edit = false;
          return i;
        });

      }).then((res) => {
        this.EnvelopeTableisBusy = false;
      });
      axios
        .get("/api/categories/names")
        .then((res) => (this.categories = res.data));
      this.GetProfileDetails();
    },
  },
  data() {
    return {
      Allsettings: { envelopeshowbudget: false, defaultenvelope: "" },
      token: "",
      Delete: {
        fromenvelopeId: null,
        toenvelopeId: null,
      },
      FundEnvelopesData: {
        profile: 1,
        FromEnvelope: null,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortBy: "category.name",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      EnvelopeTableisBusy: null,
      noenvelope: [],
      noaccount: [],
      categories: [],
      ProfileDetails: [],
      EnvelopeBalances: [],
      Envelopesfields: [
        {
          key: "CategoriesEdit",
          label: "Category",
          variant: "none",
          class: "col-2 largertext",
          tdClass: "align-centers",
          sortDirection: "asc",
        },
        {
          key: "NameEdit",
          label: "Name",
          class: "col-2 largertext",
          variant: "none",
          sortDirection: "desc",
        },

        {
          key: "balanceFormated",
          label: "Balance",
          variant: "none",
          class: "col-2 largertext",
        },
        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-1 largertext",
        },
      ],
      Profilefields: [
        {
          key: "EnvelopeEdit",
          label: "Envelope",
          variant: "none",
          class: "col-8",
          tdClass: "align-centers",
          sortDirection: "asc",
        },
        {
          key: "amountdol",
          label: "Amount",
          class: "col-3",
          variant: "none",
          sortDirection: "desc",
        },
        {
          key: "futureamount",
          label: "Future Amount",
          class: "col-3",
          variant: "none",
          sortDirection: "desc",
        },
        {
          key: "delete",
          label: "",
          class: "col-1",
          variant: "none",
          sortDirection: "desc",
        },
      ],
    };
  },
  created() {
    this.refresh();
    axios.get("/api/settings").then((r) => {
      // console.log(r);
      if (r.data) {
        this.Allsettings = r.data;
      }
    });
    // axios
    //   .get("/custcontactstatuses")
    //   .then((res) => (this.AllStatuses = res.data));
    // //.then(res => (console.log(res.data)));
    // //.catch(err => console.log(err));
  },
  computed: {
    TotalOffset() {

      // const uniqueTransactions = [...new Set([...this.noaccount, ...this.noenvelope])];
      // const totalAmount = uniqueTransactions.reduce((total, item) => total + Number(item.amount), 0);
      // if (this.NoEnvelopeTransactionsAmountTotal != 0 || this.NoAccountTransactionsAmountTotal != 0) {

      return (this.NoEnvelopeTransactionsAmountTotal - this.NoAccountTransactionsAmountTotal)
      // } else {
      //   return 0;
      // }
    },
    EnvelopeTotalAccurate() {
      if (this.noaccount?.length > 0 || this.noenvelope?.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    NoAccountTransactionsAmountTotal() {
      if (this.noaccount) {
        return this.noaccount.reduce((a, b) => a + Number(b.amount), 0);
      } else {
        return 0;
      }
    },
    NoEnvelopeTransactionsAmountTotal() {
      if (this.noenvelope) {
        return this.noenvelope.reduce((a, b) => a + Number(b.amount), 0);
      } else {
        return 0;
      }
    },
    EnvelopesfieldsCalc() {
      if (this.Allsettings.envelopeshowbudget) {
        return [
          {
            key: "CategoriesEdit",
            label: "Category",
            variant: "none",
            class: "col-2 largertext",
            tdClass: "align-centers",
            sortDirection: "asc",
          },
          {
            key: "NameEdit",
            label: "Name",
            class: "col-2 largertext",
            variant: "none",
            sortDirection: "desc",
          },

          {
            key: "balanceFormated",
            label: "Balance",
            variant: "none",
            class: "col-2 largertext",
          },
          {
            key: "BudgetAmount",
            label: "Budget",
            variant: "none",
            class: "col-1 largertext",
          },
          {
            key: "Actions",
            label: "",
            variant: "none",
            class: "col-1 largertext",
          },
        ];
      } else {
        return this.Envelopesfields;
      }
    },
    FundEnvelopesBalance() {
      if (this.FundEnvelopesData.FromEnvelope) {
        return this.EnvelopeBalances.filter(
          (i) => i.id == this.FundEnvelopesData.FromEnvelope
        )[0].balance;
      }
      return 0;
    },
    EnvelopeBalance(envelopeId) {
      if (this.EnvelopeBalances) {
        var balance = this.EnvelopeBalances.filter(
          (i) => i.id == envelopeId
        )
        if (balance.length > 0) {
          return balance[0].balance;
        } else {
          return 0;
        }
      }
      return 0;
    },
    FundingTotal() {
      if (this.ProfileDetails) {
        let sum = 0;
        this.ProfileDetails.forEach((element) => {
          sum += Number(element.amount);
        });
        return Number(sum.toFixed(2));
      }

    },
    EnvelopeTotal() {
      let sum = 0;
      this.EnvelopeBalances.forEach((element) => {
        sum += Number(element.balance);
      });
      return Number(sum.toFixed(2));
    },
    EnvelopeOptions() {
      if (this.EnvelopeBalances) {
        let a = this.EnvelopeBalances.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        a.unshift({ value: null, text: "-- Select Envelope --" });
        return a;
      } else {
        return { value: null, text: "None" };
      }
    },
    DeleteEnvelopeOptions() {
      if (this.EnvelopeOptions && this.Delete.fromenvelopeId) {
        let a = this.EnvelopeOptions.filter(
          (i) => i.id !== this.Delete.fromenvelopeId
        );
        // a.unshift({ value: null, text: "Unassigned" });
        return a;
      } else {
        return { value: null, text: "None" };
      }
    },
    CategoriesOptions() {
      if (this.categories) {
        //console.log(this.Envelopes[0].id);
        //this.FundEnvelopesData.FromEnvelope = this.Envelopes[0].id;
        return this.categories.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.name}`,
        }));
      } else {
        return { value: null, text: "None" };
      }
    },
  },
};
</script>
<style scoped>
::v-deep .classtable {
  overflow: visible;
}

/* .error {
  color: red;
}

.success {
  color: green;
} */

/* .largertext a {
  font-size: large;
}
.largertext span {
  font-size: large;
}
.largertext div {
  font-size: large;
} */
</style>
