import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import router from './router'
import money from 'v-money'
import store from "./store/index";
import CustomMoneyInputPlugin from './plugins/custom-money-input';


import './dateProtypes.js'
import "./css/global.css"
import VueApexCharts from 'vue-apexcharts'
//import VuePageTitle from 'vue-page-title'


// importing the helper
import interceptorsSetup from './services/Axiosinterceptors'

// and running it somewhere here
interceptorsSetup()

//import cookieParser from ("cookie-parser")

//Vue.use(cookieParser)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// Vue.use(VuePageTitle, {
//   prefix: 'Easy Envelopes - ',
//   //suffix: '- My App '
// })
// Vue.use(Auth0Plugin, {
//   domain,
//   clientId,
//   authorizationParams,
//   onRedirectCallback: appState => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     );
//   }
// });
// register directive v-money and component <money>

Vue.use(money, {
  decimal: ".",
  thousands: ",",
  prefix: "$ ",
  suffix: "",
  precision: 2,
  masked: false,
})

Vue.use(CustomMoneyInputPlugin, {
  decimal: ".",
  thousands: ",",
  prefix: "$ ",
  suffix: "",
  precision: 2,
  masked: false,
})

Vue.mixin({
  created() {
  },
  watch: {
    $route(to) {
       document.title = `Easy Envelopes - ${to.meta.title}`;
       //const link = document.querySelector("[rel='icon']")
       //link.setAttribute('href',to.meta.icon)
    }
  },
  computed: {
    // async AuthorizationHeader() {
    //   if (!this.$auth.loading && this.$auth.isAuthenticated) {
    //     const test = `Bearer ${await this.$auth.getTokenSilently()}`
    //     return test
    //   } else {
    //     return null
    //   }
    // },
  },
  methods: {
    formatcurency(number) {
      if (number > -0.01 && number < 0.01) {
      number = 0;
      }
      const options = { style: "currency", currency: "USD" };
      const numberFormat = new Intl.NumberFormat("en-US", options);
      return numberFormat.format(number);
    },

  /**
 * Takes a raw CSV string and converts it to a JavaScript object.
 * @param {string} text The raw CSV string.
 * @param {string[]} headers An optional array of headers to use. If none are
 * given, they are pulled from the first line of `text`.
 * @param {string} quoteChar A character to use as the encapsulating character.
 * @param {string} delimiter A character to use between columns.
 * @returns {object[]} An array of JavaScript objects containing headers as keys
 * and row entries as values.
 */
 csvToJson(text, headers, quoteChar = '"', delimiter = ',') {
  const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

  const match = line => [...line.matchAll(regex)]
    .map(m => m[2])  // we only want the second capture group
    .slice(0, -1);   // cut off blank match at the end

  const lines = text.split('\n');

  if (!headers) {
    var potentialheader
    do {
      potentialheader = match(lines.shift());
    }
    while (potentialheader.length < 3);
    var heads = potentialheader
  } else {
    var heads = headers
  }

   const unfilteredlines = lines.map(line => {
    return match(line).reduce((acc, cur, i) => {
      // Attempt to parse as a number; replace blank matches with `null`
      const val = cur.length <= 0 ? null : Number(cur) || cur;
      const key = heads[i] ?? `extra_${i}`;
      return { ...acc, [key]: val };
    }, {});
  });
  const filteredlines = unfilteredlines.filter(l => Object.keys(l).length > 0)

  return filteredlines

},

    ProperCase(text) {
      return text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    },
    ProperCasePeriod(text) {
      if (text) {
        return text.toLowerCase()
          .split('.')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      } else {
        return ""
      }


    },

  }
})

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
