import { render, staticRenderFns } from "./Income.vue?vue&type=template&id=4f9891bd&scoped=true"
import script from "./Income.vue?vue&type=script&lang=js"
export * from "./Income.vue?vue&type=script&lang=js"
import style1 from "./Income.vue?vue&type=style&index=1&id=4f9891bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9891bd",
  null
  
)

export default component.exports